<template>
    <div class="adaptiveIcons">

        <div v-if="isWeb || isAdaptive" class="adaptiveIcons__icon">
            <SvgUse
                :width="24"
                :height="24"
                id="adaptiveWeb"
            ></SvgUse>
        </div>

        <div v-if="isMobile || isAdaptive" class="adaptiveIcons__icon">
            <SvgUse
                :width="24"
                :height="24"
                id="adaptiveMobile"
            ></SvgUse>
        </div>

        <div v-if="isIframe" class="adaptiveIcons__icon">
            <SvgUse
                :width="24"
                :height="24"
                id="adaptiveIframe"
            ></SvgUse>
        </div>



    </div>
</template>

<script>
    export default {
        name: 'EditStreamLandingAdaptiveIcons',
        props: {
            type: {
                type: String,
                default: ''
            }
        },
        data() {
            return {}
        },
        computed: {
            isWeb() {
                return this.type === 'web'
            },
            isMobile() {
                return this.type === 'mobile'
            },
            isAdaptive() {
                return this.type === 'adaptive'
            },
            isIframe() {
                return this.type === 'iframe'
            },
        },
        methods: {},
        beforeMount() {
        }
    }
</script>

<style scoped lang="scss">

    @import '../../../scss/utils/mixins.scss';
    @import '../../../scss/utils/variables.scss';

    .adaptiveIcons{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &__icon{
            opacity: .7;
            width: 18px;
            height: 18px;
            margin: 0 5px;
        }
    }
</style>