<template>
    <div class="streamsFilters">
        <div class="streamsFilters__row">
            <FormInput
                :label="$lng.__('lk_webmaster_streams', 'Stream name')"
                :placeholder="$lng.__('lk_webmaster_streams', 'Enter a stream name')"
                v-model="filters.byName"
            ></FormInput>

            <FormSelectMulti
                :label="$lng.__('lk_webmaster_streams', 'Offer')"
                :placeholder="$lng.__('lk_webmaster_streams', 'Select offer')"
                v-if="optionsOffer.length"
                v-model:selected="filters.byOffer"
                :borderless=false
                :multiple=false
                :nested=false
                tooltip="Filter streams by offer"
                key="filterByGeo"
                :options=optionsOffer>
            </FormSelectMulti>

            <FormSelectMulti
                v-if="optionsGeo.length"
                :label="$lng.__('lk_webmaster_streams', 'Geo')"
                :placeholder="$lng.__('lk_webmaster_streams', 'Choose geo')"
                v-model:selected="filters.byGeo"
                :borderless=false
                :multiple=true
                :nested=false
                key="filterByGeo"
                :options=optionsGeo>
            </FormSelectMulti>

            <FormSelectMulti
                v-if="true"
                :label="$lng.__('lk_webmaster_streams', 'Domain')"
                :placeholder="$lng.__('lk_webmaster_streams', 'Choose domain')"
                v-model:selected="filters.byDomain"
                :borderless=false
                :multiple=false
                :nested=false
                key="filterByDomain"
                :options=optionsDomain>
            </FormSelectMulti>

        </div>
        <div class="streamsFilters__reset">
            <button
                v-if="isResetFiltersVisible"
                @click="resetFilters()"
                class="wv-btn--green wv-btn--text">
                {{ $lng.__('lk_webmaster_main', 'Reset') }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StreamsFilters',
    props: [
        'streamsFiltered',
        'filters'
    ],
    data() {
        return {
        };
    },
    emits: [
        'update:filters'
    ],
    computed: {
        streams() {
            return this.$store.state.webmaster.streams
        },
        isResetFiltersVisible() {
            return this.filters.byName.length > 0
                || this.filters.byOffer.length > 0
                || this.filters.byGeo.length > 0
                || this.filters.byDomain.length > 0
        },
        optionsGeo() {
            return collect(this.streams)
                .pluck('geo_name')
                .unique()
                .whereNotNull()
                .map(geo_name => { return {id: geo_name, name: geo_name} })
                .all()
        },
        optionsOffer() {
            return collect(this.streams)
                .pluck('offer_name')
                .unique()
                .whereNotNull()
                .map(offer_name => { return {id: offer_name, name: offer_name} })
                .all()
        },
        optionsDomain() {
            return collect(this.streams)
                .pluck('url')
                .map(domain => {
                    return domain
                        .replace('https://', '')
                        .replace('http://', '')
                        .split('?')[0]
                        .split('/')[0]
                })
                .unique()
                .whereNotNull()
                .map(domain => { return {id: domain, name: domain} })
                .all()
        },
    },
    methods: {
        setFilterByGeo(val) {
            this.filters.byGeo = val.label;
        },
        resetFilters() {
            this.$emit('update:filters', {...this.filters, byName: '', byGeo: [], byOffer: [], byDomain: []})
        }
    },
    beforeMount() {

    }
};
</script>

<style lang="scss">
@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.streamsFilters {
    margin-top: -20px;
    width: 100%;
    &__row {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 0 20px;
        justify-content: space-between;
        @include break(lg) {
            padding: 40px 0 20px;
            align-items: center;
            flex-direction: row;
        }
        & > * {
            margin-top: 40px;
            @include break(lg) {
                max-width: 33%;
                margin-top: 0;
                margin-right: 20px;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
    &__reset{
        padding: 0 0 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        button{
            width: 100%;
            @include break(sm){
                width: auto;
            }
        }
    }
}
</style>