<template>
    <div class="EditStreamSelectGeo">
        <div
            v-for="geo in offerGeoList"
            @click="geo.is_active ? setGeo(geo) : false"
            :data-tippy-content="geo.is_active ? null : 'To access, contact your manager'"
            class="EditStreamSelectGeo__item" :class="{'EditStreamSelectGeo__item-disabled': !geo.is_active}">

            <FormRadio
                :size="24"
                :disabled="!geo.is_active"
                :checked="isGeoActive(geo)"
            ></FormRadio>

            <div class="EditStreamSelectGeo__icon">
                <SvgUse
                    :width="40"
                    :height="40"
                    :id="'geo'+geo.code"
                ></SvgUse>
            </div>

            <div class="EditStreamSelectGeo__content">
                <div class="EditStreamSelectGeo__name">
                    {{ geo.name }}
                </div>
                <div class="EditStreamSelectGeo__rates">
                    <div class="EditStreamSelectGeo__rate">
                        {{ geo.rate.amount }} {{ geo.rate.currency ? geo.rate.currency : '&euro;'}}
                    </div>

                    <div
                        v-if="geo.bonus"
                        class="EditStreamSelectGeo__bonus">
                        <img src="/images/streams-webcoins-logo.svg" alt="Webvorkcoins logo"/>
                        {{ geo.bonus }}
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'EditStreamSelectGeo',
    props: [],
    data() {
        return {};
    },
    computed: {
        offerGeoList() {
            return collect(this.$store.state.webmaster.offer.geo)
                .sortBy('is_active')
                .reverse()
                .all()
        },
        activeGeoCode() {
            return this.$store.state.webmaster.stream.geo
        },
    },
    methods: {
        setGeo(geo) {
            this.$emit('selected', geo)
        },
        isGeoActive(geo) {
            return geo.code === this.activeGeoCode
        }
    },
    mounted() {
        setTimeout(WV.tippyMount, 3000)
    }
};
</script>

<style lang="scss">
    @import '../../../scss/utils/mixins.scss';
    @import '../../../scss/utils/variables.scss';

    .EditStreamSelectGeo{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 0 40px -5px;
        &__item{
            display: flex;
            background: white;
            margin: 5px 0 0 5px;
            padding: 15px;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            border-radius: 10px;
            width: 100%;
            height: 80px;
            @include break(sm) {
                width: calc(50% - 5px);
            }
            @include break(lg) {
                width: calc(33.33% - 5px);
            }
            @include break(xl) {
                width: calc(25% - 5px);
            }
            @include minWidth(1360) {
                width: calc(20% - 5px);
            }
            &-disabled{
                opacity: .6;
            }
        }
        &__icon{
            margin: 0 20px;
            flex: 0 0 40px;
            height: 40px;
            overflow: hidden;
            border-radius: 50%;
            border: 1px solid $color-day-bg;
        }
        &__content{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
        }
        &__name{
            font-family: $font-primary;
            font-weight: 600;
            font-size: 16px;
            letter-spacing: .5px;
        }
        &__rates{
            padding-top: 10px;
            font-family: $font-secondary;
            font-size: 15px;
            letter-spacing: 0.005em;
            font-weight: 400;
        }
    }

</style>