<template>
    <div v-if="offer" class="EditStreamOfferDetails">
        <Card>
            <div class="EditStreamOfferDetails__wrapper">
                <div class="EditStreamOfferDetails__pic">
                    <img
                        v-if="(typeof offer.photo !== 'object')"
                        :src="'https://webvork.com'+offer.photo"
                        :alt="offer.name"
                    />
                </div>
                <div class="EditStreamOfferDetails__content">
                    <div class="EditStreamOfferDetails__head">
                        <div class="EditStreamOfferDetails__name">
                            {{ offer.name }}
                        </div>
                        <div class="EditStreamOfferDetails__categories">

                        </div>
                    </div>

                    <div
                        v-if="offer.description"
                        class="EditStreamOfferDetails__description">
                        <div v-html="offer.description"></div>
                    </div>

                </div>
            </div>
        </Card>
    </div>
</template>

<script>
export default {
    name: 'EditStreamOfferDetails',
    props: [],
    data() {
        return {};
    },
    computed: {
        offer() {
            return this.$store.state.webmaster.offer;
        },
    },
    methods: {},
    beforeMount() {
    }
};
</script>

<style lang="scss">

    @import '../../../scss/utils/mixins.scss';
    @import '../../../scss/utils/variables.scss';

    .EditStreamOfferDetails {
        margin-bottom: 30px;
        width: 100%;
        &__wrapper{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: stretch;
            width: 100%;
            flex: none;
            overflow-x: auto;
            @include break(lg) {
                flex-direction: row;
                overflow-x: hidden;
            }
        }
        &__pic{
            width: 200px;
            flex: none;
            margin: 0 50px 30px 0;
            img{
                width: 200px;
            }
        }
        &__description{
            margin: 20px 0 0;
            font-size: 14px;
            padding-top: 15px;
            white-space: normal;
            line-height: 130%;
            p{
                margin-bottom: 10px;
                line-height: 120%;
                font-size: 14px;
            }
            hr{
                margin-bottom: 20px;
            }
            br{
                display: block;
                width: 100%;
                margin-bottom: 10px;
            }
            strong{
                margin-top: 15px;
            }
            a{
                text-decoration: underline;
                color: $color-day-light-blue;
                word-break: break-all;
            }
            table{
                white-space: nowrap;
            }
            ul{
                padding-inline-start: 15px;
                margin-bottom: 10px;
                li{
                    list-style: outside square;
                }
            }
        }
        &__content{
            font-size: 14px;
            white-space: normal;
            font-family: $font-secondary;
        }
        &__notice{
            margin-top: 20px;
            p{
                margin-bottom: 10px;
                line-height: 130%;
            }
        }
        &__head{}
        &__name{
            font-family: $font-primary;
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
        }
    }
</style>