<template>
    <div class="streams">

        <Loading v-if="!isStreamsLoaded"></Loading>

        <Card
            v-if="isStreamsLoaded"
            addclass="mb-30"
            :removePB="true">

            <StreamsFilters
                v-if="streams.length"
                :streamsFiltered="streams"
                v-model:filters="filters"
            ></StreamsFilters>

            <div v-if="!streams.length" class="streams__firstStreamBtn">
                <a
                    href="#"
                    @click.prevent="$router.push('/'+$route.params.lng+'/webmaster/offers')"
                    class=" wv-btn--text wv-btn--green">
                    {{ $lng.__('lk_webmaster_streams', 'Create your first stream') }}
                </a>
            </div>
        </Card>

        <Card v-if="isStreamsLoaded  && streams.length">

            <h2>{{ $lng.__('lk_webmaster_main_menu', 'Streams') }}</h2>

            <div
                class="streams__table">
                <div class="table__wrapper">
                    <table class="table">
                        <thead>
                            <tr>
                                <th class="text-left">
                                    <SortingIcons
                                        :label="$lng.__('lk_webmaster_streams', 'ID')"
                                        :isActive="sortBy==='id'"
                                        @sorted="setSortingById"
                                    ></SortingIcons>
                                </th>
                                <th class="text-left">
                                    <SortingIcons
                                        :label="$lng.__('lk_webmaster_streams', 'Name')"
                                        :isActive="sortBy==='name'"
                                        @sorted="setSortingByName"
                                    ></SortingIcons>
                                </th>
                                <th class="text-left">
                                    <SortingIcons
                                        :isActive="sortBy==='creation'"
                                        :label="$lng.__('lk_webmaster_streams', 'Creation Date')"
                                        @sorted="setSortingByCreation"
                                    ></SortingIcons>
                                </th>
                                <th class="text-left">
                                    {{ $lng.__('lk_webmaster_streams', 'URL') }}
                                </th>
                                <th class="text-left">
                                    <SortingIcons
                                        :isActive="sortBy==='geo'"
                                        :label="$lng.__('lk_webmaster_streams', 'Geo')"
                                        @sorted="setSortingByGeo"
                                    ></SortingIcons>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="stream in streamsSorted">
                                <td>
                                    {{ stream.id }}
                                </td>
                                <td>
                                    <strong>
                                        {{ stream.stream_name }}
                                    </strong>
                                    <div>
                                        {{ stream.offer_name }}
                                    </div>
                                </td>
                                <td class="text-nowrap text-left">
                                    {{ stream.created_at }}
                                </td>
                                <td>
                                    <div class="streams__table__url">
                                        <div
                                            @click="copyStreamUrlToClipboard(stream.url)"
                                            class="streams__table__url__copy">
                                            <SvgUse :width="16" :height="16" id="btnCopy"></SvgUse>
                                        </div>
                                        <a target="_blank" :href="stream.url">
                                            {{ stream.url }}
                                        </a>
                                    </div>
                                </td>
                                <td>
                                    <div class="streams__table__geo">
                                        <SvgUse :width="35" :height="35" :id="'geo'+stream.geo"></SvgUse>
                                        <div class="streams__table__geo__name">
                                            {{ $lng.__('lk_webmaster_countries', stream.geo_name) }}
                                        </div>
                                    </div>
                                </td>
                                <td width="280px">
                                    <div class="streams__table__btns">
                                        <button
                                            class="wv-btn--purpure"
                                            @click='$router.push("/"+$route.params.lng+"/webmaster/streams/edit/"+stream.id)'
                                            :data-tippy-content="$lng.__('lk_webmaster_streams', 'Edit stream')">
                                            <SvgUse :width="20" :height="20" id="btnEdit"></SvgUse>
                                        </button>
                                        <a
                                            target="_blank"
                                            @click.prevent='$router.push("/"+$route.params.lng+"/webmaster/streams/view/"+stream.id)'
                                            href="#"
                                            class="wv-btn--circle wv-btn--purpure"
                                            :data-tippy-content="$lng.__('lk_webmaster_streams', 'View stream')">
                                            <SvgUse :width="20" :height="20" id="btnView"></SvgUse>
                                        </a>

                                        <button
                                            @click="$router.push({
                                                path: '/'+$route.params.lng+'/webmaster/stat/lead',
                                                query: {
                                                    startDate: (new Date).addDays(-365).getFormattedDate(),
                                                    endDate: (new Date).addDays(1).getFormattedDate(),
                                                    stream: JSON.stringify({id: stream.id, name: stream.stream_name})
                                                }
                                            })"
                                            class="wv-btn--purpure"
                                            :data-tippy-content="$lng.__('lk_webmaster_streams', 'View statistics')">
                                            <SvgUse
                                                :width="20" :height="20" id="btnStatistic"
                                            ></SvgUse>
                                        </button>

                                        <button
                                            @click="streamToDelete={...stream, stream_id: stream.id};isShowDeleteStreamModal=true"
                                            class="wv-btn--red"
                                            :data-tippy-content="$lng.__('lk_webmaster_streams', 'Delete stream')">
                                            <SvgUse :width="20" :height="20" id="btnDelete"></SvgUse>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </Card>


        <StreamDeleteModal
            v-if="streamToDelete"
            :stream="streamToDelete"
            v-model:show="isShowDeleteStreamModal"
        ></StreamDeleteModal>

    </div>
</template>

<script>
import StreamsFilters from './StreamsFilters';
import StreamDeleteModal from '../Stream/StreamDeleteModal';

export default {
    name: 'Streams',
    props: [],
    data() {
        return {
            sortBy: 'id', // creation\id\name\geo
            sortDirection: 'desc',
            filters: {
                byName: '',
                byOffer: [],
                byGeo: [],
                byDomain: [],
            },
            isShowDeleteStreamModal: false,
            isStreamsLoaded: false,
            streamToDelete: false
        };
    },
    watch: {
        isShowDeleteStreamModal(val, oldVal) {
            if (!val) this.streamToDelete = false;
        }
    },
    computed: {
        streams() {
            return this.$store.state.webmaster.streams;
        },

        // фильтрации
        streamsFiltered() {
            return !this.filters
                ? this.streams
                : this.streams.filter(stream => {
                    let pass = true;

                    // фильтр по названию потока
                    if (this.filters.byName.length > 0) {
                        let query = this.filters.byName.toLowerCase();
                        pass = stream.stream_name.toLowerCase()
                            .indexOf(query) >= 0;
                    }

                    // фильтр по офферу
                    if (pass && this.filters.byOffer.length) {
                        pass = this.filters.byOffer[0].name === stream.offer_name;
                    }

                    // фильтр по домену
                    if (pass && this.filters.byDomain.length) {
                        pass = stream.url.indexOf(this.filters.byDomain[0].name) >= 0;
                    }

                    // фильтр по geo
                    if (pass && this.filters.byGeo.length > 0) {
                        pass = false;
                        let geoTerms = collect(this.filters.byGeo)
                            .flatten()
                            .unique()
                            .all()
                            .join('|');
                        if (geoTerms.indexOf(stream.geo_name) !== -1) pass = true;
                    }

                    return pass;
                });
        },

        // сортировки
        streamsSorted() {
            let sorted = this.streamsFiltered.sort((a, b) => {
                // сортировка по id asc
                if (this.sortBy === 'id' && this.sortDirection === 'asc') {
                    return parseInt(a.id) - parseInt(b.id);
                }
                // сортировка по id desc
                if (this.sortBy === 'id' && this.sortDirection === 'desc') {
                    return parseInt(b.id) - parseInt(a.id);
                }
                // сортировка по name asc
                if (this.sortBy === 'name') {
                    return (a.stream_name).localeCompare(b.stream_name);
                }
                // сортировка по geo asc
                if (this.sortBy === 'geo') {
                    let first = (typeof a.geo === 'object') ? '' : a.geo;
                    let last = (typeof b.geo === 'object') ? '' : b.geo;
                    return first.localeCompare(last);
                }

                // сортировка по creation asc
                if (this.sortBy === 'creation') {
                    let first = (typeof a.created_at === 'object') ? '' : a.created_at;
                    let last = (typeof b.created_at === 'object') ? '' : b.created_at;
                    return first.localeCompare(last);
                }

            });

            let needReverse = (this.sortBy === 'name' && this.sortDirection === 'desc')
                || (this.sortBy === 'creation' && this.sortDirection === 'desc')
                || (this.sortBy === 'geo' && this.sortDirection === 'desc');

            return needReverse ? sorted.reverse() : sorted;
        },
    },
    methods: {
        applyFilters(filters) {
            this.filters = filters;
        },
        setSorting(by, direction) {
            this.sortBy = by;
            this.sortDirection = direction;
        },
        setSortingById(direction) {
            this.setSorting('id', direction);
        },
        setSortingByName(direction) {
            this.setSorting('name', direction);
        },
        setSortingByCreation(direction) {
            this.setSorting('creation', direction);
        },
        setSortingByGeo(direction) {
            this.setSorting('geo', direction);
        },
        copyStreamUrlToClipboard(text) {
            WV.copyTextToClipboard(text).then(() => {
                this.$toast.success(this.$lng.__('lk_webmaster_streams', 'URL copied to clipboard'))
            })
        },
    },
    beforeMount() {
        this.$store.dispatch('webmaster/getStreams')
            .then(() => {
                this.isStreamsLoaded = true;
            });
    },
    beforeUnmount() {
        this.$store.commit('webmaster/clean', {streams: []});
    },
    mounted() {
        setTimeout(WV.tippyMount, 3000);
    },
    components: {
        StreamsFilters,
        StreamDeleteModal
    }
};
</script>

<style lang="scss">
@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.streams {
    margin-top: 100px;
    @include break(md) {
        margin-top: 0;
    }
    width: 100%;
    &__firstStreamBtn {
        padding: 20px 0 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
    &__table {
        width: calc(100% + 60px);
        margin: 20px -30px -30px;
        font-weight: 300;
        overflow: hidden;
        &__url {
            height: 40px;
            line-height: 40px;
            display: flex;
            flex-direction: row;
            align-items: center;
            border: 1px solid $color-day-line;
            cursor: pointer;
            padding: 0 10px;
            color: $color-day-light-blue;
            text-decoration: underline;
            border-radius: 3px;
            position: relative;
            white-space: nowrap;
            overflow: hidden;
            max-width: 300px;
            &__copy{
                position: absolute;
                right: 0;
                top:0;
                bottom:0;
                padding: 0 10px;
                background: white;
                display: flex;
                align-items: center;
                justify-content: center;
                svg{
                    flex:none;
                    opacity: .5;
                    @include break(hover){
                        opacity: 1;
                    }
                }

            }
        }
        &__geo {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            white-space: nowrap;
            &__name {
                padding-left: 10px;
            }
        }
        &__btns {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            flex-wrap: nowrap;
            & > * {
                margin-right: 10px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
</style>