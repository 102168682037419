<template>
    <Modal
        :title="$lng.__('lk_webmaster_main', 'Delete stream')"
        v-if="show"
        @close="close()">
        <div class="pt-20 text-center">

            <Loading v-if="deleteRequestTime"></Loading>

            <template v-if="!deleteRequestTime">
                <p class="pb-30">
                    {{
                        $lng.__('lk_webmaster_main', 'Delete %s thread? This action is irreversible.')
                            .replace('%s', (this.stream ? this.stream.stream_name : ''))
                    }}
                </p>
                <div class="wv-btns wv-btns-center">
                    <button
                        class="wv-btn--red wv-btn--text"
                        @click="deleteStream">
                        {{ $lng.__('lk_webmaster_main', 'Delete stream') }}
                    </button>
                    <button
                        class="wv-btn--green wv-btn--text"
                        @click="close()">
                        {{ $lng.__('lk_webmaster_main', 'Cancel') }}
                    </button>
                </div>
            </template>
        </div>
    </Modal>
</template>

<script>
    export default {
        name: 'StreamDeleteModal',
        props: {
            stream: {
                type: Object,
                required: true
            },
            show: {
                type: Boolean,
                default: false,
                require: true
            }
        },
        emits: [
            'update:show'
        ],
        data() {
            return {
                deleteRequestTime: false,
            }
        },
        computed: {},
        methods: {
            deleteStream() {
                this.deleteRequestTime = true
                Promise.all([
                    this.$store.dispatch('webmaster/getPrelandings', {offer_id: this.stream.offer_id, geo: this.stream.geo}),
                    this.$store.dispatch('webmaster/getLandings', {offer_id: this.stream.offer_id, geo: this.stream.geo})
                ]).then(() => {
                    this.isLoadingLandings = false;
                })

                this.$store.dispatch('webmaster/deleteStream', this.stream).then(() => {
                    if  (this.$route.name === 'webmaster.streams') {
                        this.$store.dispatch('webmaster/getStreams').then(() => {
                            this.close()
                        })
                    } else {
                        this.$router.push('/'+this.$route.params.lng+'/webmaster/streams')
                    }
                })
            },
            close() {
                this.$emit('update:show', false)
            }
        },
        beforeMount() {
        }
    }
</script>

<style lang="scss">

</style>