<template>
    <div class="editStreamLandings">
        <div class="editStreamLandings__head">
            <div class="editStreamLandings__title">
                {{ title }}
            </div>

            <div
                v-if="hasLandings && !hasCutomDomain"
                class="editStreamLandings__tabs">
                <div
                    v-show="landingsActual.length"
                    :class="{'editStreamLandings__tab-active': tabActive==='Actual'}"
                    @click="tabActive='Actual'"
                    class="editStreamLandings__tab">
                    {{ $lng.__('lk_webmaster_streams_crud', 'Actual') }}
                </div>
                <div
                    v-show="landingsTop.length"
                    :class="{'editStreamLandings__tab-active': tabActive==='Top'}"
                    @click="tabActive='Top'"
                    class="editStreamLandings__tab">
                    {{ $lng.__('lk_webmaster_streams_crud', 'Top') }}
                </div>
                <div
                    v-show="landingsArchive.length"
                    :class="{'editStreamLandings__tab-active': tabActive==='Archive'}"
                    @click="tabActive='Archive'"
                    class="editStreamLandings__tab">
                    {{ $lng.__('lk_webmaster_streams_crud', 'Archive') }}
                </div>
            </div>

            <div class="editStreamLandings__viewMode">
                <!--                <ToggleViewMode
                                    v-if="hasLandings"
                                    :key="id+'-viewmode'"
                                    :id="id+'-viewmode'"
                                    :mode="viewMode"
                                    @switch="setViewMode"
                                ></ToggleViewMode>-->
            </div>
        </div>

        <div
            v-if="viewMode==='grid' && hasFilteredLandings"
            class="editStreamLandings__cards">
            <div
                v-for="landing in landingsFiltered"
                :class="{'editStreamLandings__card-active': selected===parseInt(landing.id)}"
                class="editStreamLandings__card">
                <div
                    class="editStreamLandings__card__image">
                    <img
                        :src="$config.baseUrl+landing.preview_src"
                        alt="">
                </div>
                <div class="editStreamLandings__card__type">
                    <EditStreamLandingAdaptiveIcons
                        :type="landing.type"
                    ></EditStreamLandingAdaptiveIcons>
                </div>
                <div
                    @click="dispatchSelect(landing)"
                    class="editStreamLandings__card__title">
                    <FormRadio
                        :size="20"
                        :checked="selected===parseInt(landing.id)"
                    ></FormRadio>
                    <span>{{ landing.name }}</span>
                </div>
                <div class="editStreamLandings__card__url">
                    <a target="_blank" :href="landing.url">
                        {{ landing.url }}
                    </a>
                </div>
                <div class="editStreamLandings__card__btns">
                    <button
                        @click="dispatchSelect(landing)"
                        class="wv-btn--green pl-50 pr-50">
                        {{ $lng.__('lk_webmaster_streams_crud', 'Select') }}
                    </button>
                    <a
                        target="_blank"
                        :href="landing.download_url"
                        :data-tippy-content="$lng.__('lk_webmaster_streams_crud', 'Archive with preconfigured API')"
                        class="wv-btn--green">
                        <SvgUse :width="14" :height="20" id="download"></SvgUse>
                    </a>
                </div>
            </div>
        </div>

        <div
            v-if="viewMode==='table' && hasFilteredLandings"
            class="editStreamLandings__list">
            <div
                v-for="landing in landingsVisible"
                class="editStreamLandings__listItem">
                <div class="editStreamLandings__listItem__content">
                    <div @click="dispatchSelect(landing)">
                        <FormRadio
                            :size="20"
                            :checked="selected===parseInt(landing.id)"
                        ></FormRadio>
                    </div>
                    <div
                        @click="dispatchSelect(landing)"
                        class="editStreamLandings__listItem__title">
                        {{ landing.name }}
                    </div>
                    <div class="editStreamLandings__listItem__url">
                        <span>URL</span>
                        <a target="_blank" :href="landing.url">
                            {{ landing.url }}
                        </a>
                    </div>
                    <div
                        v-if="expandedLandingPreview === landing.id"
                        class="editStreamLandings__listItem__preview block-arrow-top">
                        <img :src="$config.baseUrl+landing.preview_src" alt="">
                    </div>
                </div>
                <div class="editStreamLandings__listItem__btns">
                    <div class="editStreamLandings__listItem__type">
                        <EditStreamLandingAdaptiveIcons
                            :type="landing.type"
                        ></EditStreamLandingAdaptiveIcons>
                    </div>
                    <!--                    <button
                                            @click="expandLandingPreview(landing)"
                                            class="wv-btn&#45;&#45;green mr-10"
                                            :class="{'wv-btn&#45;&#45;green-active': expandedLandingPreview === landing.id}">
                                            <SvgUse :width="20" :height="20" id="preview"></SvgUse>
                                        </button>-->
                    <a
                        target="_blank"
                        :href="landing.download_url"
                        :data-tippy-content="$lng.__('lk_webmaster_streams_crud', 'Archive with preconfigured API')"
                        class="wv-btn--green">
                        <SvgUse :width="20" :height="20" id="download"></SvgUse>
                    </a>
                </div>
            </div>
        </div>

        <ShowMore
            :all="landingsFiltered.length"
            :visible="landingsVisibleCount"
            @more="showMore()"
        ></ShowMore>

        <div
            v-if="!hasFilteredLandings"
            class="editStreamLandings__notice">
            {{
                $lng.__('lk_webmaster_streams_crud',
                    'There are no Landing pages for this country.\nContact your manager to add a landing page.')
            }}
        </div>

    </div>
</template>

<script>

import ToggleViewMode from '../../../components/ToggleViewMode';
import EditStreamLandingAdaptiveIcons from './EditStreamLandingAdaptiveIcons';
import Offers from '../Offers/Offers';

export default {
    name: 'EditStreamSelectLanding',
    props: {
        title: {
            type: String,
            default: ''
        },
        landings: {
            type: Array,
            default: []
        },
        id: {
            type: String,
        },
        selected: {
            type: Number,
        },
    },
    data() {
        return {
            viewMode: 'table',
            tabActive: 'Actual',
            expandedLandingPreview: false,
            landingsVisibleCount: 15,
        };
    },
    mounted() {
        this.$nextTick(function() {
            this.landings.forEach((landing) => {
                landing.download_url = this.$config.baseUrl + landing.download_url;
            });
        });

        if (!this.landingsActual.length) {
            if (this.landingsTop.length) {
                this.tabActive = 'Top';
            } else {
                this.tabActive = 'Archive';
            }
        }

        setTimeout(WV.tippyMount, 3000);
    },
    watch: {
        landings: {
            handler(landings, oldLandings) {
                this.tabActive = 'Actual';
            },
            deep: true
        }
    },
    computed: {
        landingsFiltered() {
            return this.landings.filter(landing => {
                if (this.tabActive === 'Actual') {
                    return landing.is_actual;
                }
                if (this.tabActive === 'Top') {
                    return landing.is_top;
                }
                if (this.tabActive === 'Archive') {
                    return landing.is_archived;
                }
            });
        },
        landingsVisible() {
            return this.landingsFiltered.slice(0, this.landingsVisibleCount);
        },
        hasLandings() {
            return this.landings.length > 0;
        },
        hasFilteredLandings() {
            return this.landingsFiltered.length > 0;
        },
        landingsTop() {
            return collect(this.landings)
                .where('is_top', true)
                .all();
        },
        landingsActual() {
            return collect(this.landings)
                .where('is_actual', true)
                .all();
        },
        landingsArchive() {
            return collect(this.landings)
                .where('is_archive', true)
                .all();
        },
    },
    methods: {
        setViewMode(mode) {
            this.viewMode = mode;
        },
        dispatchSelect(landing) {
            this.$emit('select', landing);
        },
        expandLandingPreview(landing) {
            this.expandedLandingPreview = this.expandedLandingPreview === landing.id
                ? false
                : landing.id;
        },
        hasLandingsForTab(tabname) {
            return this.landings.filter(landing => {
                    if (tabname === 'Actual') {
                        return landing.is_actual;
                    }
                    if (tabname === 'Top') {
                        return landing.is_top;
                    }
                    if (tabname === 'Archive') {
                        return landing.is_archived;
                    }
                })
                .length() > 0;
        },
        showMore() {
            this.landingsVisibleCount += 15;
        },
    },
    components: {
        ToggleViewMode,
        EditStreamLandingAdaptiveIcons,
    }
};
</script>

<style lang="scss">
@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

.editStreamLandings {
    margin-bottom: 30px;
    &__head {
        width: 100%;
        padding: 0 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background: $color-day-table;
        border-radius: 10px 10px 0 0;
        border-bottom: 1px solid $color-day-line;
        flex-wrap: wrap;
        @include break(xl) {
            height: 80px;
        }
    }
    &__viewMode {
        order: 2;
        @include break(lg) {
            order: 3;
        }
    }
    &__tabs {
        display: flex;
        flex-direction: row;
        order: 3;
        height: 50px;
        width: 100%;
        justify-content: center;
        @include break(lg) {
            height: 80px;
            width: auto;
            order: 2;
            justify-content: flex-start;
        }
    }
    &__tab {
        font-family: $font-secondary;
        font-weight: 400;
        font-size: 16px;
        line-height: 18px;
        height: 100%;
        padding: 0 20px;
        color: $color-day-gray;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 3px solid transparent;
        cursor: pointer;
        @include break(xs) {
            padding: 0 30px;
        }
        &-active {
            cursor: default;
            color: $color-day-black;
            border-bottom: 3px solid $color-day-light-green;
        }
    }
    &__title {
        font-family: $font-primary;
        font-weight: bold;
        font-size: 18px;
        margin-left: -10px;
        width: calc(100% - 80px);
        line-height: 22px;
        letter-spacing: 0.005em;
        padding: 20px 0;
        order: 1;
        @include break(sm) {
            width: auto;
            margin-left: 0;
            font-size: 20px;
        }
        @include break(md) {
            line-height: 30px;
            font-size: 24px;
        }
    }
    &__cards {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-right: -5px;
    }
    &__card {
        width: 100%;
        flex: none;
        background: $color-day-white;
        margin-top: 5px;
        margin-right: 5px;
        padding: 20px;
        border-radius: 5px;
        transition: .2s filter ease;
        @include break(sm) {
            width: calc(50% - 5px);
        }
        @include break(lgs) {
            width: calc(33.3% - 5px);
        }
        @include break(xl) {
            width: calc(25% - 5px);
        }
        @include break(xxl) {
            width: calc(20% - 5px);
        }
        @include break(hover) {
            transition: .2s filter ease;
            filter: drop-shadow(0px 50px 50px rgba(0, 0, 0, 0.25));
        }
        &__image {
            height: 180px;
            overflow: hidden;

            img {
                object-fit: cover;
                object-position: 0 0;
                width: 100%;
            }
        }
        &__type {
            margin: 20px 0;
        }
        &__title {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
            font-family: $font-secondary;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.5px;
            & > * {
                padding-right: 10px;
            }
        }
        &__url {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $color-day-light-blue;
            font-family: $font-secondary;
            font-size: 13px;
            line-height: 20px;
            overflow: hidden;
            white-space: nowrap;
            span {
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 0.5px;
                padding-right: 5px;
            }
        }
        &__btns {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
            button:nth-child(1) {
                margin-right: 10px;
            }
        }
    }
    &__list {
        margin-top: 3px;
        background: $color-day-white;
        width: 100%;
        max-width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        &Item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 0 30px;
            min-height: 80px;
            min-width: 800px;
            &:nth-child(2n) {
                background: $color-day-table;
            }
            &__content {
                display: flex;
                flex-direction: row;
                align-items: center;
                position: relative;
            }
            &__title {
                font-family: $font-secondary;
                font-size: 14px;
                line-height: 18px;
                font-weight: bold;
                letter-spacing: 0.5px;
                margin: 0 20px;
            }
            &__url {
                color: $color-day-light-blue;
                font-family: $font-secondary;
                font-size: 14px;
                line-height: 20px;
                font-weight: 300;
                overflow: hidden;
                white-space: nowrap;
                span {
                    font-weight: 700;
                    text-transform: uppercase;
                    letter-spacing: 0.5px;
                    margin-right: 10px;
                }
                position: relative;
            }
            &__preview {
                width: 300px;
                height: 300px;
                position: absolute;
                top: calc(100% + 10px);
                left: 100%;
                margin-left: -200px;
                background: white;
                padding: 20px;
                filter: drop-shadow(0px 20px 30px rgba(0, 0, 0, 0.25));
                z-index: 99;
            }
            &__type {
                margin-right: 20px;
            }
            &__btns {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }
    &__notice {
        background: $color-day-white;
        padding: 20px 30px;
        font-family: $font-secondary;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: $color-day-gray;
    }
}
</style>