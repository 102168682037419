<template>
    <div class="streamView">
        <Card>
            <h2>{{ $lng.__('lk_webmaster_streams', 'Stream Details') }}</h2>

            <Loading v-if="!stream"></Loading>

            <template v-if="stream">
                <div class="streamView__table">
                    <div class="table__wrapper">
                        <table  class="table mt-30 mb-30">
                        <tbody>
                            <tr>
                                <td>{{ $lng.__('lk_webmaster_streams', 'ID') }}</td>
                                <td>
                                    {{ stream.stream_id }}
                                </td>
                            </tr>
                            <tr>
                                <td>{{ $lng.__('lk_webmaster_streams', 'Stream') }}</td>
                                <td>{{ stream.stream_name }}</td>
                            </tr>
                            <tr v-if="offer">
                                <td>{{ $lng.__('lk_webmaster_streams', 'Offer') }}</td>
                                <td>
                                    {{ offer.name }} (ID {{ offer.id }})
                                </td>
                            </tr>
                            <tr>
                                <td>{{ $lng.__('lk_webmaster_streams', 'Created at') }}</td>
                                <td>{{ stream.created_ts }}</td>
                            </tr>
                            <tr v-if="stream.geo">
                                <td>{{ $lng.__('lk_webmaster_streams', 'Country') }}</td>
                                <td>
                                    <div class="flex-row-center">
                                        <SvgUse
                                            :width="25"
                                            :height="25"
                                            :id="'geo'+stream.geo"
                                        ></SvgUse>
                                        <span class="pl-10">{{ $lng.__('lk_webmaster_countries', stream.geo_name) }}</span>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="stream.url_prelanding">
                                <td>{{ $lng.__('lk_webmaster_streams', 'Prelanding') }}</td>
                                <td>
                                    <a
                                        class="text-link"
                                        :href="stream.url_prelanding"
                                        target="_blank">
                                        {{ stream.url_prelanding }}
                                    </a>
                                </td>
                            </tr>
                            <tr v-if="stream.url_landing">
                                <td>{{ $lng.__('lk_webmaster_streams', 'Landing') }}</td>
                                <td>
                                    <a
                                        class="text-link"
                                        :href="stream.url_landing"
                                        target="_blank">
                                        {{ stream.url_landing }}
                                    </a>
                                </td>
                            </tr>
                            <tr v-if="stream.yandex_m_id">
                                <td>Yandex M ID</td>
                                <td>{{ stream.yandex_m_id }}</td>
                            </tr>
                            <tr v-if="stream.google_a_id">
                                <td>Google A. ID</td>
                                <td>{{ stream.google_a_id }}</td>
                            </tr>
                            <tr v-if="stream.google_gtag_id">
                                <td>Google Tag Manager ID</td>
                                <td>{{ stream.google_gtag_id }}</td>
                            </tr>
                            <tr v-if="stream.facebook_id">
                                <td>Facebook ID</td>
                                <td>{{ stream.facebook_id }}</td>
                            </tr>
                            <tr v-if="stream.facebook_track">
                                <td>{{ $lng.__('lk_webmaster_streams', 'Facebook Track') }}</td>
                                <td>{{ stream.facebook_track }}</td>
                            </tr>
                            <tr v-if="stream.facebook_id2">
                                <td>Facebook2 ID</td>
                                <td>{{ stream.facebook_id2 }}</td>
                            </tr>
                            <tr v-if="stream.facebook_track2">
                                <td>Facebook2 Track</td>
                                <td>{{ stream.facebook_track2 }}</td>
                            </tr>
                            <template v-if="stream.shopwindow_active">
                                <tr v-if="stream.shopwindow_active.comebacker">
                                    <td>{{ $lng.__('lk_webmaster_streams', 'Comebacker Showcase') }}</td>
                                    <td>{{ $lng.__('lk_webmaster_streams', 'Active') }}</td>
                                </tr>
                                <tr v-if="stream.shopwindow_active.success">
                                    <td>{{ $lng.__('lk_webmaster_streams', 'Success Page Showcase') }}</td>
                                    <td>{{ $lng.__('lk_webmaster_streams', 'Active') }}</td>
                                </tr>
                            </template>
                            <tr v-if="stream.url">
                                <td>{{ $lng.__('lk_webmaster_streams', 'URL') }}</td>
                                <td>
                                    <div class="streamView__table__url">
                                        <a target="_blank" class="text-link" :href="stream.url">
                                            {{ stream.url }}
                                        </a>                                    
                                        <div
                                            @click="copyStreamUrlToClipboard(stream.url)"
                                            class="streamView__table__url__copy">
                                            <SvgUse :width="16" :height="16" id="btnCopy"></SvgUse>
                                        </div>    
                                    </div>                                
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </div>

                <div class="streamView__btns">
                    <button
                        @click='$router.push("/"+$route.params.lng+"/webmaster/streams/edit/"+$route.params.stream_id)'
                        class="wv-btn--text wv-btn--green-active">
                        {{ $lng.__('lk_webmaster_main', 'Edit') }}
                    </button>
                    <button
                        @click="isShowDeleteStreamModal=true"
                        class="wv-btn--text btn wv-btn--red">
                        {{ $lng.__('lk_webmaster_main', 'Delete') }}
                    </button>
                </div>
            </template>

        </Card>

        <StreamDeleteModal
            :stream="stream"
            v-model:show="isShowDeleteStreamModal"
        ></StreamDeleteModal>

    </div>
</template>

<script>

import StreamDeleteModal from '../Stream/StreamDeleteModal'

export default {
    name: 'StreamView',
    props: [],
    data() {
        return {
            isShowDeleteStreamModal: false,
        }
    },
    computed: {
        stream() {
            return this.$store.state.webmaster.stream || false;
        },
        offer() {
            return this.$store.state.webmaster.offer || false;
        },
    },
    methods: {
        copyStreamUrlToClipboard(text) {
            WV.copyTextToClipboard(text).then(() => {
                this.$toast.success(this.$lng.__('lk_webmaster_streams', 'URL copied to clipboard'))
            })
        },
    },
    beforeMount() {
        this.$store.dispatch('webmaster/getStream', {
            stream_id: this.$route.params.stream_id
        }).then(() => {
            this.$store.dispatch('webmaster/getOffer', this.stream.offer_id);
        })
    },
    beforeUnmount() {
        this.$store.commit('webmaster/clean', {
            stream: false,
            offer: false,
        })
    },
    components: {
        StreamDeleteModal,
    }
};
</script>

<style lang="scss">
@import '../../../scss/utils/mixins.scss';
@import '../../../scss/utils/variables.scss';

    .streamView{
        width: 100%;
        margin-top: 90px;
        @include break(md) {
            margin-top: 0;
        }
        &__table{
            margin: 0 -30px;
            tbody{
                tr{
                    td:first-child{
                        @include break(md) {
                            padding-left: 30px;
                        }

                    }
                }
            }
            &__url{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                &__copy{
                    padding: 0 0 0 16px;
                    cursor: pointer;
                    opacity: .5;
                    @include break(hover){
                        opacity: 1;
                    }
                }
            }
        }
        &__btns{
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            flex-wrap: wrap;
            @include break(md) {
                flex-wrap: nowrap;
            }
            button{
                width: 100%;
                margin-top: 20px;
                @include break(md) {
                    margin-top: 0;
                    width: auto;
                    margin-left: 20px;
                }

            }
        }
    }

</style>
